exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blogs-blog-js": () => import("./../../../src/pages/blogs/blog.js" /* webpackChunkName: "component---src-pages-blogs-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-all-blogs-js": () => import("./../../../src/templates/all-blogs.js" /* webpackChunkName: "component---src-templates-all-blogs-js" */),
  "component---src-templates-single-js": () => import("./../../../src/templates/single.js" /* webpackChunkName: "component---src-templates-single-js" */),
  "component---src-templates-tag-category-posts-js": () => import("./../../../src/templates/tag-category-posts.js" /* webpackChunkName: "component---src-templates-tag-category-posts-js" */)
}

